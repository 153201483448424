<template>
  <div>
    <!-- faq search section -->
    <b-card
      no-body
      class="faq-search"
      :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
    >
      <b-card-body class="text-center">
        <h2 class="text-primary">
          FAQ
          <!--                          <div-->
          <!--                            class="text-center"-->
          <!--                            style="color:rgb(248,241,122); font-size: x-small;"-->
          <!--                          >-->
          <!--                            {{ noticeStore }}-->
          <!--                          </div>-->
<!--          <b-badge-->
<!--              variant="primary"-->
<!--              style="font-size: xx-small"-->
<!--          >-->
<!--            DB 연계-->
<!--          </b-badge>-->
        </h2>
        <b-card-text class="mb-2">
          종목별 게임 방법, 배당, 충전/환전 등 자주 묻는 질문을 검색하세요
        </b-card-text>

        <!-- form -->
        <b-form class="faq-search-input">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="검색"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <!-- form -->
      </b-card-body>
    </b-card>
    <!--/ faq search section -->
    <b-card>
      <b-row>
        <!-- Filter -->
        <!--
        <b-col md="12" class="my-1">
          <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" > Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        -->
        <!-- Filter. -->
        <!-- bs-table -->
        <b-col
          md="12"
          class="my-1"
        >
          <b-table
            show-empty
            striped
            hover
            responsive
            empty-text="(1) 검색결과가 없습니다."
            empty-filtered-text="(2) 검색결과가 없습니다."
            :items="fetchFaq"
            :fields="fields1"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            class="text-center"
            @filtered="onFiltered"
            @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
          >
            <template
              #cell(no)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.index + 1 }}
            </template>
            <template #cell(category)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(title)="data">
              <div
                size="sm"
                style="text-align: left; min-width: 10rem"
              >
                {{ data.item.title }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #row-details="row">
              <b-card style="background-color: #1b2337">
                <b-row class="mb-2">
                  <b-col cols="12">
                    <b-form-group
                      class="font-small-3"
                      label="FAQ 상세"
                      label-for="main-notice"
                      label-cols-md="1"
                    >
                      <b-form-textarea
                        id="faq-detail"
                        v-model="row.item.content"
                        class="font-small-3"
                        size="sm"
                        placeholder="FAQ 상세내용"
                        style="color:rgb(229,166,48)"
                        rows="9"
                        :readonly="isEditing"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-col>
        <!-- bs-table. -->
        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="center"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <!-- pagination. -->
      </b-row>
    </b-card>
    <!-- frequently asked questions tabs pills -->
    <!--    <section id="faq-tabs">-->
    <!--      <b-tabs-->
    <!--        vertical-->
    <!--        content-class="col-12 col-md-8 col-lg-9"-->
    <!--        pills-->
    <!--        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"-->
    <!--        nav-class="nav-left"-->
    <!--      >-->

    <!--        &lt;!&ndash; payment tab &ndash;&gt;-->
    <!--        <b-tab-->
    <!--          v-for="(categoryObj, categoryName, index) in faqData"-->
    <!--          :key="categoryName"-->
    <!--          :active="!index"-->
    <!--        >-->

    <!--          &lt;!&ndash; title &ndash;&gt;-->
    <!--          <template #title>-->
    <!--            &lt;!&ndash;            <feather-icon&ndash;&gt;-->
    <!--            &lt;!&ndash;              :icon="categoryObj.icon"&ndash;&gt;-->
    <!--            &lt;!&ndash;              size="18"&ndash;&gt;-->
    <!--            &lt;!&ndash;              class="mr-1"&ndash;&gt;-->
    <!--            &lt;!&ndash;            />&ndash;&gt;-->
    <!--            <span class="font-weight-bold">{{ categoryObj.title }}</span>-->
    <!--          </template>-->

    <!--          <faq-question-answer :options="categoryObj" />-->
    <!--        </b-tab>-->
    <!--        &lt;!&ndash;/ payment tab &ndash;&gt;-->

    <!--        &lt;!&ndash; sitting lady image &ndash;&gt;-->
    <!--        <template #tabs-end>-->
    <!--          &lt;!&ndash;          <b-img&ndash;&gt;-->
    <!--          &lt;!&ndash;            fluid&ndash;&gt;-->
    <!--          &lt;!&ndash;            :src="require('@/assets/images/illustration/faq-illustrations.svg')"&ndash;&gt;-->
    <!--          &lt;!&ndash;            class="d-none d-md-block mt-auto"&ndash;&gt;-->
    <!--          &lt;!&ndash;          />&ndash;&gt;-->
    <!--        </template>-->
    <!--        &lt;!&ndash;/ sitting lady image &ndash;&gt;-->
    <!--      </b-tabs>-->
    <!--    </section>-->
    <!--/ frequently asked questions tabs pills -->

  </div>
</template>

<script>
import {
  BFormTextarea,
  BFormText,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BBadge,
  BCard,
  BCardBody,
  BForm,
  BCardText,
  BRow,
  BCol,
  BButton,
  BTable, BPagination, BInputGroupAppend,
} from 'bootstrap-vue'
import { apolloClient } from '@/vue-apollo'
import { FindManyNoticeFaqDocument } from '@/generated/graphql'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_FAQ } from '@/store/notice/action'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BFormTextarea,
    BFormText,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BPagination,
    BTable,
    BForm,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BInputGroup,
    BCardText,
    BInputGroupAppend,
    BButton,
    FaqQuestionAnswer,
    BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      faqDetailRow: 0,
      faqDetailCategory: '',
      faqDetailTitle: '',
      faqDetailContent: '',
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      isEditing: true,
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'title', sortable: false, label: '제목', thStyle: 'col3',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
      ],
      editorOption: {
        modules: {
          toolbar: [
            [''],
          ],
        },
      },
    }
  },
  mounted() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: 'faq',
      menuCategory: '',
    })
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchFaq: 'fetchFaq',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchFaq
    },
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchFaq: FETCH_FAQ,
    }),
    async fetchData() {
      const mySite = this.userData.site
      await this.$fetchFaq({
        site: mySite,
      })
      this.totalRows = this.fetchFaq.length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record, index, type) {
      this.faqDetailRow = record.no
      this.faqDetailTitle = record.title
      this.faqDetailCategory = record.category
      this.faqDetailContent = record.content
      this.$refs['my-modal'].show()
    },
    // async fetchData() {
    //   const { data } = await apolloClient.query({
    //     query: FindManyNoticeFaqDocument,
    //     variables: {
    //       where: {
    //         OR: [
    //           {
    //             title: {
    //               contains: this.faqSearchQuery,
    //             },
    //           },
    //           {
    //             content: {
    //               contains: this.faqSearchQuery,
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   })
    //   console.log(data.findManyFaq)
    //   this.faqData = {}
    //
    //   const gb = _.groupBy(data.findManyFaq, 'category')
    //
    //   for (const findManyFaqElement in gb) {
    //     this.faqData[findManyFaqElement] = {
    //       icon: 'CreditCardIcon',
    //       title: '',
    //       subtitle: '',
    //       qandA: [],
    //     }
    //
    //     for (const findManyFaqElementElement of gb[findManyFaqElement]) {
    //       this.faqData[findManyFaqElement].title = findManyFaqElementElement.title
    //       this.faqData[findManyFaqElement].subtitle = findManyFaqElementElement.content
    //       this.faqData[findManyFaqElement].qandA.push({
    //         question: findManyFaqElementElement.title,
    //         ans: findManyFaqElementElement.content,
    //       })
    //     }
    //   }
    //
    //   console.log(this.faqData)
    //
    //   // console.log(this.faqData)
    //   // this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => { this.faqData = res.data })
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
